<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
import errorHandler from '../../../helpers/errorHandler';
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Credentials Form",
  },
  mixins: [validationMixin],
  validations:{
    form:{
      api_key: {
        required,
      },
      temp_api_key: {
        required,
      },
    }
  },
  data() {
    return {
      title: "Videos",
      items: [
        {
          text: "Work center",
          href: "/home",
        },
        {
          text: "Video form",
          href: "/sessions",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      submit: false,
      type: null,
      form:{
        api_key: null,
        temp_api_key: null,
      },
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods:{
    async handleSubmit() {
      this.submit = true;
      await this.$v.$touch();
      if(!this.$v.$anyError ){
        // requests for the create
        this.$store.dispatch("video/addCredentials",this.form).then(
           ()=>{
            this.$router.push('/videos/credentials');
          }
        ).catch((err)=>{
          errorHandler.methods.errorMessage(err);
        });
      }
    },
    async handleUpdate(){
      this.submit = true;
      if(!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("video/editCredentials",this.form).then(
           ()=>{
            this.$router.go(-1);
          }
        ).catch((err)=>{
          errorHandler.methods.errorMessage(err);
        });
      }
    },
  },
  async created(){
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('video/getCredentials',this.$route.params.id).then( async token =>{
            this.form.api_key = token.api_key;
            this.form.temp_api_key = token.temp_api_key;
        }).catch( err =>{
            errorHandler.methods.errorMessage(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Video</h2>
                    <h2 v-if="type == 'edit'">Edit Video</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="API key:"
                        label-for="input-D"
                    >
                        <input v-if="type == 'create' || (type == 'edit' && (form.api_key != null))" v-model="form.api_key" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.api_key.$error }" placeholder="Enter api key" />
                        <div v-if="submit && $v.form.api_key.$error" class="invalid-feedback">
                            <span v-if="!$v.form.api_key.required">This field is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Temp API key:"
                        label-for="input-D"
                    >
                        <input v-if="type == 'create' || (type == 'edit' && form.temp_api_key != null)" v-model="form.temp_api_key" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.temp_api_key.$error }" placeholder="Enter temp api key" />
                        <div v-if="submit && $v.form.temp_api_key.$error" class="invalid-feedback">
                            <span v-if="!$v.form.temp_api_key.required">This field is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>